import React from "react";
import "./MainHeading.scss";

const MainHeading = ({
    heading,
    mainParagraph,
    secondParagraph,
    thirdParagraph,
}) => (
    <div className="main-heading">
        <h1 className="main-heading__heading">{heading}</h1>
        <div className="main-heading__paragraphs-container">
            {mainParagraph && (
                <p className="main-heading__paragraph main-heading__paragraph--main">
                    {mainParagraph}
                </p>
            )}
            <div className="main-heading__paragraphs">
                {secondParagraph && (
                    <p className="main-heading__paragraph">{secondParagraph}</p>
                )}
                {thirdParagraph && (
                    <p className="main-heading__paragraph main-heading__paragraph--last">
                        {thirdParagraph}
                    </p>
                )}
            </div>
        </div>
    </div>
);

MainHeading.defaultProps = {
    heading: "",
    mainParagraph: "",
};

export default MainHeading;
