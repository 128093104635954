import React from "react";
import { ReactSVG } from "react-svg";
import "./BenefitsListItem.scss";

const BenefitsListItem = ({
    benefit,
    icon,
    cardHeight,
    iconFromMarkupPath,
}) => (
    <>
        {benefit.description ? (
            <div
                className="benefits-card extended"
                style={{
                    height: cardHeight,
                }}
            >
                <div className="benefits-card__container">
                    {iconFromMarkupPath ? (
                        <img
                            src={iconFromMarkupPath}
                            className="benefits-card__icon"
                            alt="Icon"
                        />
                    ) : (
                        <ReactSVG
                            src={icon}
                            className="benefits-card__icon"
                            alt="Icon"
                        />
                    )}
                    <p className="benefits-card__title">{benefit.title}</p>
                </div>
                <p className="benefits-card__description">
                    {benefit.description}
                </p>
            </div>
        ) : (
            <div className="benefits-card">
                <ReactSVG
                    src={icon}
                    className="benefits-card__icon"
                    alt="Icon"
                />
                <div className="benefits-card__title">
                    <p>{benefit.title}</p>
                </div>
            </div>
        )}
    </>
);

export default BenefitsListItem;
