import React from "react";
import BenefitsListSection from "@common/CommonSections/BenefitsListSection/BenefitsListSection";
import { BENEFIT_LIST_ITEMS } from "../../constants";

const BenefitsSection = () => {
    return (
        <BenefitsListSection
            listItems={BENEFIT_LIST_ITEMS}
            heading="Employee benefits"
            headingDescription="We are passionate about everything we do, and with Impressit you will get:"
        />
    );
};

export default BenefitsSection;
