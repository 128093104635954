import React, { useEffect, useState } from "react";
import "./Careers.scss";
import MainHeading from "@common/MainHeading/MainHeading";
import CircleBLur from "@common/CircleBlur/CircleBlur";
import useWindowDimensions from "@hooks/useWindowDimensions";
import ImagesCollage from "./components/ImagesCollage/ImagesCollage";
import BenefitsSection from "./components/BenefitsSection/BenefitsSection";
import VacanciesSection from "./components/VacanciesSection/VacanciesSection";
import OurOfficeSection from "./components/OurOfficeSection/OurOfficeSection";

const Careers = ({ data }) => {
    const { isDesktop } = useWindowDimensions();
    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        if (isDesktop !== undefined) {
            setDidMount(true);
        }
        return () => setDidMount(false);
    }, [isDesktop]);

    useEffect(() => {
        if (document !== undefined && window !== undefined && didMount) {
            // find h2 with needed text
            const xpath = "//h2[text()='Open positions']";
            const matchingElement = document.evaluate(
                xpath,
                document,
                null,
                XPathResult.FIRST_ORDERED_NODE_TYPE,
                null
            ).singleNodeValue;
            if (window.leadToOpenPositionSection) {
                matchingElement.scrollIntoView({ block: "center" });
                window.leadToOpenPositionSection = false;
                const header = document.querySelector("header.header");
                if ((isDesktop === undefined || !isDesktop) && header) {
                    header.style.background = "rgba(43, 9, 52, 52)";
                }
            }
        }
    }, [didMount]);

    return (
        <div className="careers-page">
            <MainHeading
                heading="Careers"
                mainParagraph="We do believe that our team is exceptional.
                We do believe that our work changes the world. We do believe
                that Impressit can be your dream job place."
            />
            <CircleBLur
                blur="blur(25rem)"
                width="35.625rem"
                left="-17.5rem"
                showOnTablet={false}
                height="35.625rem"
            />
            <ImagesCollage />
            <BenefitsSection />
            <VacanciesSection data={data} />
            {/* <OurOfficeSection /> */}
        </div>
    );
};

export default Careers;
