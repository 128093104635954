import React, { useState, useEffect } from "react";
import PageHeading from "@common/PageHeading/PageHeading";
import { EMPTY_VACANCY_IDS } from "../../../../constants";
import VacancyCard from "./VacancyCard/VacancyCard";

import "./VacanciesSection.scss";

const VacanciesSection = ({ data }) => {
    const [vacancies, setVacancies] = useState([]);

    useEffect(() => {
        const currVacancies = data?.restApiApiVacancies?.result?.data || [];
        setVacancies(
            currVacancies.filter(
                vacancy => !EMPTY_VACANCY_IDS.includes(parseInt(vacancy.id, 10))
            )
        );
    }, [data]);

    return (
        <div className="vacancies-section">
            <PageHeading heading="Open positions" />
            <div className="vacancies-section__actual-vacancies">
                {vacancies.length &&
                    vacancies.map(vacancy => (
                        <VacancyCard vacancy={vacancy} key={vacancy.id} />
                    ))}
            </div>
        </div>
    );
};

export default VacanciesSection;
