import hat from "@images/hat.svg";
import bag from "@images/bag.svg";
import relocation from "@images/relocation.svg";
import tax from "@images/tax.svg";
import cocktail from "@images/cocktail.svg";
import firstAid from "@images/first-aid.svg";

export const BENEFIT_LIST_ITEMS = [
  {
    title: "Professional education and training budget",
    icon: hat,
  },
  { 
    title: "24 working days vacation", 
    icon: bag 
  },
  {
    title: "Full tax compensation",
    icon: tax,
  },
  {
    title: "WOW team-building events",
    icon: cocktail,
  },
  {
    title: "Paid sick leave",
    icon: firstAid,
  },
  {
    title: "Relocation assistance",
    icon: relocation,
  },
];
