import React from "react";
import PageHeading from "@common/PageHeading/PageHeading";
import BenefitsListItem from "./BenefitsListItem/BenefitsListItem";
import "./BenefitsListSection.scss";

const BenefitsListSection = ({
    listItems,
    heading,
    headingDescription,
    cardHeight,
}) => (
    <div className="benefits-section">
        <PageHeading heading={heading} mainParagraph={headingDescription} />
        <div
            className="benefits-section-items"
            style={
                cardHeight && {
                    gridTemplateRows: "auto",
                }
            }
        >
            {listItems.map((item, index) => (
                <BenefitsListItem
                    key={index}
                    benefit={item}
                    icon={item.icon}
                    iconFromMarkupPath={item.iconFromMarkupPath}
                    cardHeight={cardHeight}
                />
            ))}
        </div>
    </div>
);

export default BenefitsListSection;
